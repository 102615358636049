import "./signupBonusClaim.scss";

import { IonButton, IonCol, IonGrid, IonLabel, IonRow, IonText } from "@ionic/react";
import { useToast } from "@src/appV2/lib";
import { formatPhone } from "@src/lib/utils";
import { FC, Fragment } from "react";
import { useDispatch } from "react-redux";

import { claimBonus } from "./api";
import { useDefinedAgent } from "../store/helperHooks";
import { updateAgent } from "../store/session";

const SignupBonusClaim: FC<{}> = () => {
  const agent = useDefinedAgent();
  const dispatch = useDispatch();
  const { showSuccessToast } = useToast();

  const collectBonus = () => {
    claimBonus();
    showSuccessToast("You have successfully collected your bonus.");
    dispatch(updateAgent({ hasClaimedBonus: true }));
  };

  const { signupConfig = {} } = agent;

  return (
    <Fragment>
      <IonGrid class="grid-centered">
        <IonRow>
          <IonCol class="ion-margin-bottom">
            <img width="250" height="250" src="assets/logo/dollar-bunny.svg" alt="Clipboard logo" />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonText color="dark" class="custom-style-title">
              Woohoo! Thanks
              <IonLabel style={{ marginLeft: "2px" }}> {agent.name}</IonLabel>{" "}
            </IonText>
            <IonText style={{ display: "block", lineHeight: "1.2" }} color="text">
              we're thrilled you're joining us.{" "}
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol class="ion-margin-top">
            <IonLabel></IonLabel>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol style={{ width: "320px" }}>
            <IonText color="text">
              Collect your
              <IonLabel>
                {" "}
                <b>${signupConfig.signingBonusAmount1} </b>
              </IonLabel>
              bonus (we'll send a Venmo request to
              <b style={{ margin: "0 4px 0 2px" }}>{formatPhone(String(agent.phone))}</b>) and start
              picking up shifts! You'll receive your second
              <b> ${signupConfig.signingBonusAmount2} </b>
              bonus after you
              <b> {signupConfig.eventName}</b>
            </IonText>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            <IonButton class="ion-margin-top" size="large" color="primary" onClick={collectBonus}>
              Collect Bonus and Enter App
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </Fragment>
  );
};

export { SignupBonusClaim };
