import { Network } from "@capacitor/network";
import { Preferences } from "@capacitor/preferences";
import { isDefined } from "@clipboard-health/util-ts";
import { api } from "@src/app/api";
import { AppType, LocationType } from "@src/app/common/location";
import { SIGNED_TYPE, TIMESHEET_TYPE } from "@src/app/hcpShifts/constants";
import { SHIFT_ACTION_CHECK_TYPES } from "@src/app/hcpShifts/constants";
import { SelectedFile } from "@src/app/shiftSignature/timecard/model";
import { logEvent } from "@src/appV2/lib/analytics";
import { Worker } from "@src/appV2/Worker/api/types";
import { USER_EVENTS } from "@src/constants/userEvents";
import {
  Agent,
  ExtraWorkedTimeParameters,
  Shift,
  ShiftStages,
  TimeRange,
  TimecardV2,
} from "@src/lib/interface";
import {
  recordShiftTime,
  uploadCaliforniaTimesheet,
  uploadSubmittedTimeSheet,
  uploadTimecardV2,
} from "@store/ongoingShifts/apiV2";
import { startOfMinute } from "date-fns";
import { isEmpty } from "lodash";
import moment from "moment-timezone";
import { Dispatch } from "redux";

import { addRating, createAccountLog, fetchAgentShifts, ongoingShiftsUploadTimecard } from "./api";
import { addRating as addRatingV2, recordShiftTimeKeepingForNFC } from "./apiV2";
import { ActionType, FetchAgentShiftRequest, OngoingShiftStore } from "./model";
import { promiseInSequence } from "../../../utils/promises";
import { ActionType as sessionActionType } from "../../store/session";
import { GetState } from "../store.model";

const ONGOING_SHIFT_STORE_KEY = "ongoingShiftStore";

let networkHandler;

const networkListener = (dispatch: Dispatch, getState: GetState) => (status) => {
  if (status.connected) {
    offlineSync(dispatch, getState);
  }
};

export const syncActionsWhenNetworkIsReachable = () => (dispatch: Dispatch, getState: GetState) => {
  offlineSync(dispatch, getState);
};

const init =
  () =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    if (!networkHandler) {
      networkHandler = Network.addListener(
        "networkStatusChange",
        networkListener(dispatch, getState)
      );
    }

    fetchStateFromLocal(dispatch);
    const {
      session: { agent, env },
    } = getState();
    if (env && agent) {
      const shiftList = await fetchShiftList(dispatch);
      fetchShiftInfoList(dispatch, shiftList);
    }
  };

const offlineSync = async (dispatch: Dispatch, getState: GetState): Promise<void> => {
  const { actionList } = getState().ongoingShiftStore;
  await hitRecordShiftTimeFailure(dispatch, actionList["RECORD_SHIFT_TIME_FAILURE"]);
  await hitOfflineSyncUploadTimeCard(dispatch, actionList["UPLOAD_TIMECARD"]);
  await hitUAddRating(dispatch, actionList["ADD_RATING"]);
};

const fetchStateFromLocal = async (dispatch: Dispatch): Promise<void> => {
  const ret = await Preferences.get({ key: ONGOING_SHIFT_STORE_KEY });

  if (!ret.value) {
    return;
  }

  let ongoingShiftsStore = JSON.parse(ret.value) as Partial<OngoingShiftStore>;

  if (isEmpty(ongoingShiftsStore)) {
    ongoingShiftsStore = {};
  }

  ongoingShiftsStore.isLoading = false;
  const { actionList } = ongoingShiftsStore;

  if (isDefined(actionList)) {
    const defaultActionList = {
      RECORD_SHIFT_TIME_FAILURE: [],
      UPLOAD_TIMECARD: [],
      ADD_RATING: [],
    };

    Object.keys(defaultActionList).forEach(function (key) {
      const value = defaultActionList[key];
      if (!isDefined(actionList[key])) {
        actionList[key] = value;
      }
    });
  }

  dispatch({
    type: ActionType.SET_STATE_FROM_LOCAL,
    data: ongoingShiftsStore,
  });
};

const fetchShiftList = async (dispatch: Dispatch): Promise<Shift[] | undefined> => {
  const status = await Network.getStatus();
  if (!status.connected) {
    return;
  }
  const query: FetchAgentShiftRequest = {
    shiftDate: undefined,
    upcoming: true,
  };

  const shiftDetails = await fetchAgentShifts(query);
  if (isEmpty(shiftDetails)) {
    return;
  }

  dispatch({
    type: ActionType.SET_SHIFT_LIST,
    data: shiftDetails,
  });

  return shiftDetails;
};

const fetchShiftInfoList = async (dispatch: Dispatch, shiftList): Promise<void> => {
  const status = await Network.getStatus();
  if (!status.connected) {
    return;
  }

  const shiftInfoList: Shift[] = [];

  if (isEmpty(shiftList)) {
    return;
  }

  for (const shift of shiftList) {
    const shiftInfo = await api.shift.fetchShiftInfo(shift._id);
    if (!isEmpty(shiftInfo)) {
      shiftInfoList.push(shiftInfo);
    }
  }

  dispatch({
    type: ActionType.SET_SHIFT_INFO_LIST,
    data: shiftInfoList,
  });
};

const hitRecordShiftTimeFailure = async (dispatch: Dispatch, paramsList): Promise<void> => {
  if (isEmpty(paramsList)) {
    return;
  }

  const paramsExecuted: any[] = [];
  for (const query of paramsList) {
    await createAccountLog(query.failureReason, query.agentId, query.shiftId);

    paramsExecuted.push(query);
  }

  filterParams(dispatch, paramsExecuted, ActionType.FILTER_RECORD_SHIFT_TIME_FAILURE);
};

// We don't save timecard on offline mode, to receive Instantpay, HCP needs be online to upload timecard
const hitOfflineSyncUploadTimeCard = (dispatch: Dispatch, paramsList): Promise<void> =>
  promiseInSequence("hitOfflineSyncUploadTimeCard", async () => {
    if (isEmpty(paramsList)) {
      return;
    }
    const paramsExecuted: any[] = [];
    for (const query of paramsList) {
      const updatedShift = await ongoingShiftsUploadTimecard(
        query.selectedFile,
        query.shiftId,
        query.locationType,
        query.appType,
        query.connectivityMode
      );
      if (isEmpty(updatedShift)) {
        return;
      }

      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: query.shiftId,
          updatedShift,
        },
      });
      paramsExecuted.push(query);
    }
    filterParams(dispatch, paramsExecuted, ActionType.FILTER_UPLOAD_TIMECARD);
  });

const hitUAddRating = async (dispatch: Dispatch, paramsList): Promise<void> => {
  if (isEmpty(paramsList)) {
    return;
  }

  const paramsExecuted: any[] = [];

  for (const query of paramsList) {
    const result = await addRating(query.request);
    if (isEmpty(result)) {
      return;
    }
    paramsExecuted.push(query);
  }
  filterParams(dispatch, paramsExecuted, ActionType.FILTER_ADD_RATING);
};

const actionRecordShiftTimeFailure =
  (failureReason: string, agent: Agent, shiftId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    const status = await Network.getStatus();

    if (!status.connected) {
      dispatch({
        type: ActionType.PUSH_RECORD_SHIFT_TIME_FAILURE,
        data: {
          failureReason,
          agentId: agent.userId?.toString(),
          shiftId: shiftId,
          timestamp: moment().format(),
        },
      });
    } else {
      await createAccountLog(failureReason, agent.userId?.toString() as string, shiftId);
    }
  };

const actionRecordShiftTime =
  (
    shiftId: string,
    stage: ShiftStages,
    location: number[],
    locationType: LocationType,
    appType: AppType,
    onModalClose?: Function
  ) =>
  (dispatch: Dispatch): Promise<boolean> =>
    promiseInSequence("actionRecordShiftTime", async () => {
      const updatedShift = await recordShiftTime(
        shiftId,
        stage,
        location,
        locationType,
        appType,
        onModalClose
      );
      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: shiftId,
          updatedShift,
        },
      });

      return true;
    });

const actionRecordShiftTimeWithNfc =
  (shiftId: string, stage: ShiftStages, appType: string, onModalClose?: Function) =>
  (dispatch: Dispatch): Promise<boolean> =>
    promiseInSequence("actionRecordShiftTimeWithNfc", async () => {
      const updatedShift = await recordShiftTimeKeepingForNFC(
        shiftId,
        stage,
        appType,
        startOfMinute(new Date()).toISOString(),
        SHIFT_ACTION_CHECK_TYPES.NFC.toString(),
        onModalClose
      );

      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: shiftId,
          updatedShift,
        },
      });
      return true;
    });

const actionUploadTimeCardV2 =
  (selectedFile: string, type: string, fileBlob: Blob | undefined, shiftId: string) =>
  (dispatch: Dispatch, getState: GetState): Promise<TimecardV2 | {}> =>
    promiseInSequence("actionUploadTimeCardV2", async () => {
      const status = await Network.getStatus();
      if (!status.connected) {
        const updateShift = {
          files: [{ _id: shiftId, url: selectedFile }],
          _id: shiftId,
          status: "UNVERIFIED",
        };
        dispatch({
          type: ActionType.UPDATE_SHIFT,
          data: {
            shiftId: shiftId,
            updatedShift: updateShift,
          },
        });
        dispatch({
          type: ActionType.PUSH_UPLOAD_TIMECARD,
          data: {
            shiftId,
            selectedFile,
            timestamp: moment().format(),
          },
        });
        return updateShift;
      }
      const updatedShift = await uploadTimecardV2(type, fileBlob, shiftId);
      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: shiftId,
          updatedShift,
        },
      });
      return updatedShift;
    });

const submitShiftTimeCard =
  ({
    file,
    submitClockInOut,
    submitLunchInOut,
    shiftId,
    digitallySignedBy,
    fileType,
    fileBlob,
    extraWorkedTime,
  }: {
    file: string;
    submitClockInOut: TimeRange;
    submitLunchInOut: TimeRange;
    shiftId: string;
    digitallySignedBy: string;
    fileType: string;
    fileBlob: Blob | undefined;
    extraWorkedTime?: ExtraWorkedTimeParameters[];
  }) =>
  async (dispatch: Dispatch, getState: GetState): Promise<TimecardV2 | {}> => {
    const status = await Network.getStatus();
    if (!status.connected) {
      const updateShift = {
        files: [{ _id: shiftId, url: file }],
        _id: shiftId,
        status: "UNVERIFIED",
      };
      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: shiftId,
          updatedShift: updateShift,
        },
      });
      dispatch({
        type: ActionType.PUSH_UPLOAD_TIMECARD,
        data: {
          shiftId,
          file,
          submitClockInOut,
          submitLunchInOut,
          timestamp: moment().format(),
          extraWorkedTime,
        },
      });
      return updateShift;
    }
    const updatedShift = await uploadSubmittedTimeSheet({
      submitClockInOut,
      submitLunchInOut,
      shiftId,
      facilityEmployeeName: digitallySignedBy,
      fileType,
      fileBlob,
      extraWorkedTime,
    });
    dispatch({
      type: ActionType.UPDATE_SHIFT,
      data: {
        shiftId: shiftId,
        updatedShift,
      },
    });
    return updatedShift;
  };

export const submitCaliforniaTimecard =
  ({
    shiftId,
    submittedFiles,
    submitClockInOut,
    submitLunchInOut,
    digitallySignedByHCP,
    digitallySignedBy,
    stationWingUnitFloor,
    nursingServiceAssignment,
    location,
    extraWorkedTime,
  }: {
    shiftId: string;
    submittedFiles: SelectedFile[];
    submitClockInOut: TimeRange;
    submitLunchInOut: TimeRange;
    digitallySignedByHCP: string;
    digitallySignedBy: string;
    stationWingUnitFloor: string;
    nursingServiceAssignment: string;
    location?: string[];
    extraWorkedTime?: ExtraWorkedTimeParameters[];
  }) =>
  async (dispatch: Dispatch, getState: GetState): Promise<TimecardV2 | {}> => {
    const [{ file: hcpSignatureFile }, { file: hcfSignatureFile }] = submittedFiles;
    const updatedShiftOffline = {
      files: [
        {
          _id: shiftId,
          url: hcpSignatureFile,
          timesheet: TIMESHEET_TYPE.PHOTO,
          signedType: SIGNED_TYPE.HCP,
          digitallySignedBy: digitallySignedByHCP,
        },
        {
          _id: shiftId,
          url: hcfSignatureFile,
          timesheet: TIMESHEET_TYPE.PHOTO,
          signedType: SIGNED_TYPE.HCF,
          digitallySignedBy: digitallySignedBy,
        },
      ],
      _id: shiftId,
      status: "UNVERIFIED",
    };
    const status = await Network.getStatus();
    if (!status.connected) {
      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: shiftId,
          updatedShift: updatedShiftOffline,
        },
      });
      return updatedShiftOffline;
    }
    const updatedShift = await uploadCaliforniaTimesheet({
      shiftId,
      submittedFiles,
      submitClockInOut,
      submitLunchInOut,
      digitallySignedByHCP,
      digitallySignedBy,
      stationWingUnitFloor,
      nursingServiceAssignment,
      location,
      extraWorkedTime,
    });
    if (!updatedShift) {
      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: shiftId,
          updatedShift: updatedShiftOffline,
        },
      });
      return updatedShiftOffline;
    }
    dispatch({
      type: ActionType.UPDATE_SHIFT,
      data: {
        shiftId: shiftId,
        updatedShift,
      },
    });
    return updatedShift;
  };

/**
 * @deprecated
 */
const actionAddRating =
  (request, updateWorkerCache: (data: Partial<Worker>) => void) =>
  async (dispatch: Dispatch): Promise<boolean> =>
    promiseInSequence("actionAddRating", async () => {
      const status = await Network.getStatus();
      const doesFacilityReviewExists = request.shift && request.shift.rating;
      if (!status.connected) {
        let updateShift;
        if (doesFacilityReviewExists) {
          updateShift = {
            rating: {
              ...request.shift.rating,
              AGENT: request.rating,
            },
          };
        } else {
          updateShift = {
            rating: {
              FACILITY: request.rating,
            },
          };
        }

        dispatch({
          type: ActionType.UPDATE_SHIFT,
          data: {
            shiftId: request.shiftId,
            updatedShift: updateShift,
          },
        });
        dispatch({
          type: ActionType.PUSH_ADD_RATING,
          data: {
            request,
            timestamp: moment().format(),
          },
        });
        return true;
      }
      const updatedShift = await addRating(request);

      if (updatedShift.status === 200 && !doesFacilityReviewExists) {
        logEvent(USER_EVENTS.SUBMITTED_FACILITY_REVIEW);
      }

      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: request.shiftId,
          updatedShift,
        },
      });
      if (updatedShift["appRatingStatus"]) {
        updateWorkerCache({
          appRatingStatus: updatedShift["appRatingStatus"],
        });
        dispatch({
          type: sessionActionType.UPDATE_AGENT,
          data: {
            agent: { appRatingStatus: updatedShift["appRatingStatus"] },
          },
        });
      }

      return true;
    });

const actionAddRatingV2 =
  (request) =>
  async (dispatch: Dispatch): Promise<boolean> =>
    promiseInSequence("actionAddRatingV2", async () => {
      const status = await Network.getStatus();
      if (!status.connected) {
        let updateShift;
        if (request.shift && request.shift.rating) {
          updateShift = {
            rating: {
              ...request.shift.rating,
              AGENT: request.rating,
            },
          };
        } else {
          updateShift = {
            rating: {
              FACILITY: request.rating,
            },
          };
        }

        dispatch({
          type: ActionType.UPDATE_SHIFT,
          data: {
            shiftId: request.shiftId,
            updatedShift: updateShift,
          },
        });
        dispatch({
          type: ActionType.PUSH_ADD_RATING,
          data: {
            request,
            timestamp: moment().format(),
          },
        });
        return true;
      }
      const updatedShift = await addRatingV2(request);
      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: request.shiftId,
          updatedShift,
        },
      });
      return true;
    });

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const filterParams = (dispatch, paramsExecuted, type) => {
  paramsExecuted.map((param) => {
    return dispatch({
      type: type,
      data: {
        timestamp: param.timestamp,
      },
    });
  });
};

export {
  init,
  actionRecordShiftTimeFailure,
  actionAddRating,
  ONGOING_SHIFT_STORE_KEY,
  actionUploadTimeCardV2,
  actionAddRatingV2,
  actionRecordShiftTime,
  actionRecordShiftTimeWithNfc,
  submitShiftTimeCard,
};
